import React, { Component } from "react";


class Store extends Component {
  constructor(props) {
    super(props);
    this.store = {
      address:null,
      total_available:0,
      total_locked:0,
      poolContract: "TTAAxcyh2SE2ecAsQaGt1vJPKMM2EoJwge",
      tronWeb: {
        installed: false,
        loggedIn: false,
      },
      rewardPools: [
        {
          token: 'KODX',
          icon: '/kodx.png',
          price: 0,
          change: +2.90,
          total: 0,
          totalusd: 0,
          available: 0,
          all_market: 0,
          all_borrowed: 0,
          borrow_apy: 0,
          deposit_apy: 0,
          locked: 0,
          borrowed: 0,
          active: true,
          TRC20:"TTUwzoZAK6rpDjpSh8B2XFTnxGfbMLHJaq",
          tokenDecimals:6,
        },{
          token: 'USDT',
          icon: '/usdt.png',
          price: 1.01,
          change: -0.01,
          total: 0,
          totalusd: 0,
          available: 0,
          all_market: 0,
          all_borrowed: 0,
          borrow_apy: 0,
          deposit_apy: 0,
          locked: 0,
          borrowed: 0,
          active: true,
          TRC20:"TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
          tokenDecimals:6,
        },
      ],
      currentPool:         {
          token: 'KODX',
          icon: '/kodx.png',
          price: 0,
          change: 2.90,
          total: 0,
          totalusd: 0,
          available: 0,
          all_market: 0,
          all_borrowed: 0,
          borrow_apy: 0,
          deposit_apy: 0,
          locked: 0,
          borrowed: 0,
          active: true,
          TRC20:"TTUwzoZAK6rpDjpSh8B2XFTnxGfbMLHJaq",
          tokenDecimals:6,
        },
      
    };
  }
  
  getStore(index) {
    //console.log(this.store[index]);
    return(this.store[index]);
  };

  setStore(obj) {
    this.store = {...this.store, ...obj}
    // console.log(this.store)
  };


  componentDidMount() {
    this.getPoolValues();
  }


}

var store = new Store();

export default {
  store: store,
};

