import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <section>
      <div className="container">
        <div className="rp ft sb">
          <div className="footer-main-div">
            <div className="footer-social-icons">
              <ul>
                <li>
                  <a href="https://t.me/kodxchat" target="blank">
                    <i className="fab fa-telegram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/kodxofficial?s=09" target="blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-menu-one">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="https://kingofdefi.org/#roadmap">Roadmap</a>
                </li>
                <li>
                  <a href="https://kingofdefi.org/#products">Products</a>
                </li>
                <li>
                  <a href="https://kingofdefi.org/#contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="footer-bottom">
              <p>© 2021 Copyright </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
