import React, { Component } from "react";
import "./Markets.css";
import Store from "../../store/store";

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d === undefined ? "." : d,
    t = t === undefined ? "," : t,
    s = n < 0 ? "" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

const store = Store.store;

class Markets extends Component {
  constructor(props) {
    super(props);
    console.log(store.getStore("rewardPools"));
    const rewardPools = store.getStore("rewardPools");
    this.state = {
      tab: 0,
      rewardPools: rewardPools,
      tabIndex: 0,
      
    };
  }

  toggleButtons(e) {
    if (window.innerWidth < 769) {
      e.target.closest(".asset").classList.toggle("showButtons");
    }
  }

  showModal(e) {
    e.target
      .closest(".asset")
      .querySelector(".modal")
      .classList.toggle("activeModal");
  }

  closeModal(e) {
    e.target.closest(".modal").classList.toggle("activeModal");
  }

  changeTab = (_rewardPool) => {
    store.setStore({
      currentPool: _rewardPool,
    });
    this.props.history.push("/app");
  };

  getPoolValues = async () => {
    const rewardPools = store.getStore("rewardPools");
    this.setState({
      tab: 1,
      rewardPools: rewardPools,
    });
  };

  renderPools = () => {
    const rewardPools = store.getStore("rewardPools");

    return rewardPools
      .filter((rewardPool) => {
        if (rewardPool.active) {
          return true;
        } else {
          return false;
        }
      })
      .map((rewardPool) => {
        return this.renderRewardPool(rewardPool);
      });
  };

  renderRewardPool = (rewardPool) => {
    return (
      <div className="asset" onClick={(e) => this.toggleButtons(e)}>
        <div className="asset-main" onClick={(e) => e.stopPropagation()}>
          <div className="asset-logo">
            <img src={rewardPool.icon} alt="" />
          </div>

          <div className="title">
            <h6>{rewardPool.token}</h6>
            <div className="price">
              $<span>{rewardPool.price.formatMoney(2, ".", ",")}</span>{" "}
              <span className={rewardPool.change > 0 ? "changed" : "changed-minus"}>
                {rewardPool.change > 0 ? "+" : "-"}
                {rewardPool.change.formatMoney(2, ".", ",")}%
              </span>
            </div>
          </div>
        </div>
        <div className="asset-total">
          <div className="price">
            {rewardPool.all_market.formatMoney(2, ".", ",")}
          </div>
          <div className="number">
            $<span>{(rewardPool.all_market * rewardPool.price).formatMoney(2, ".", ",")}</span>
          </div>
        </div>
        <div className="asset-title">
          <h6>{rewardPool.token}</h6>
        </div>
        <div className="asset-avaible">
          $<span>{(rewardPool.all_borrowed * rewardPool.price).formatMoney(2, ".", ",")}</span>
        </div>
        <div className="asset-locked">
          <span>{Number(rewardPool.deposit_apy)}%</span>
        </div>
        <div className="asset-locked">
          <span>{Number(rewardPool.borrow_apy)}%</span>
        </div>



      </div>
    );
  };

  componentDidMount() {}
  //             <div className={`col flex-row ${this.state.tab === 0 ? "blue-card" : ""}`} onClick={ () => this.changeTab(0) }>
  render() {
    const total_available = store.getStore("total_available");
    const total_locked = store.getStore("total_locked");
    const balance = store.getStore("balance");
    return (
      <main className="nb">
        <div className="container">
          <h1>Markets</h1>
          <div className="balances-block">
            <div className="total-balance">
              <h6>Balance</h6>
              <div className="number">
                
                {balance}
                <span> TRX</span>
              </div>
            </div>
            <div className="right-balance-block">
              <div className="avaible">
                <h6>Total Available</h6>
                <div className="number">
                  <span>$</span>{total_available.toFixed(2)}
                </div>
              </div>
              <div className="locked">
                <h6>
                  Total Locked{" "}
                  <svg
                    width="9"
                    height="12"
                    viewBox="0 0 9 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.75008 4.33334H7.20841V3.25001C7.20841 1.75501 5.99508 0.541672 4.50008 0.541672C3.00508 0.541672 1.79175 1.75501 1.79175 3.25001V4.33334H1.25008C0.654248 4.33334 0.166748 4.82084 0.166748 5.41667V10.8333C0.166748 11.4292 0.654248 11.9167 1.25008 11.9167H7.75008C8.34591 11.9167 8.83341 11.4292 8.83341 10.8333V5.41667C8.83341 4.82084 8.34591 4.33334 7.75008 4.33334ZM4.50008 9.20834C3.90425 9.20834 3.41675 8.72084 3.41675 8.12501C3.41675 7.52917 3.90425 7.04167 4.50008 7.04167C5.09591 7.04167 5.58341 7.52917 5.58341 8.12501C5.58341 8.72084 5.09591 9.20834 4.50008 9.20834ZM6.17925 4.33334H2.82091V3.25001C2.82091 2.32376 3.57383 1.57084 4.50008 1.57084C5.42633 1.57084 6.17925 2.32376 6.17925 3.25001V4.33334Z"
                      fill={this.props.darkTheme ? "#fff" : "#111"}
                    />
                  </svg>
                </h6>
                <div className="number">
                  <span>$</span>{total_locked.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div class="assets-block">
            <div class="asset-header">
              <a href="" className="w20pr">Assets</a>
              <a href="">Market size</a>
              <a href=""></a>
              <a href="">Total borrowed</a>
              <a href="">Deposit APY</a>
              <a href="">Borrow APR</a>
            </div>
            {this.renderPools()}
          </div>
        </div>
      </main>
    );
  }
}

export default Markets;
