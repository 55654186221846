import React, { Component } from "react";
import { connect } from "react-redux";
import Utils from "../../TronUtils";
import { withRouter } from 'react-router-dom';
import "./Header.css";
import Store from "../../store/store";
import axios from 'axios';
import { fromWei, toWei, toBN, BN, } from 'web3-utils';



const store = Store.store;
var BigInteger = require("big-integer");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      balance: "",
      checkboxmenu: false,
      prices: {
              KODX:{
                price:0,
                
              },


      }
    };
  }

  getAccountInfo = async () => {};

  componentDidMount() {
    this.getAccountInfo();
  }
  
  fillPools = (prices) => {
    var rewardPools = store.getStore("rewardPools");
    rewardPools.map((rewardPool) => {
       var token =  rewardPool.token;
       rewardPool.price = parseFloat(prices[token].price);
       rewardPool.change = parseFloat(prices[token].change);
    });
    store.setStore({
      rewardPools: rewardPools,
    });
    this.checktotals();
  };
  
  navigateTo = (key) => {
    this.checkbalances();
    this.props.history.push(key);
    this.setState({ checkboxmenu: false });
  }
  
  checktotals = () => {
    setTimeout( async () => {
      var rewardPools = store.getStore("rewardPools");
      var total_available = 0;
      var total_locked = 0;

      rewardPools.map((rewardPool) => {
        total_locked = total_locked + rewardPool.locked * rewardPool.price;
        total_available = total_available + rewardPool.total * rewardPool.price;
        console.log(rewardPool.total);
        console.log(rewardPool.price);
      });
      store.setStore({
        total_locked: total_locked,
        total_available: total_available,
      });
        console.log("total_available checktotals");
        console.log(total_available);
      }, 1000);
      
  }

  timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  checkbalances = async () => {
    setTimeout( async () => {
      var rewardPools = store.getStore("rewardPools");
      await rewardPools.map( async (rewardPool) => {
        const ACCOUNT = store.getStore("address");
        const CONTRACT = store.getStore("poolContract");
        let factoryContract = await window.tronWeb
          .contract()
          .at(CONTRACT);
        var contractTRC20 = await window.tronWeb
          .contract()
          .at(rewardPool.TRC20);
        const balance = await contractTRC20.methods.balanceOf(ACCOUNT).call();
        const ethbalance = this.tokenFromWei(balance, rewardPool.tokenDecimals)
        rewardPool.total = ethbalance;
        
        await this.timeout(150);
        
        const lockedbalance = await factoryContract.methods.balanceOf(rewardPool.TRC20,ACCOUNT).call();
        const ethlockedbalance = this.tokenFromWei(lockedbalance, rewardPool.tokenDecimals);
        rewardPool.locked = ethlockedbalance;
        
        await this.timeout(150);
        
        const availablebalance = await factoryContract.methods.totalSupply(rewardPool.TRC20).call();
        const ethavailablebalance = this.tokenFromWei(availablebalance, rewardPool.tokenDecimals);
        rewardPool.available = ethavailablebalance;
        
        await this.timeout(150);

        const all_market = await contractTRC20.methods.balanceOf(CONTRACT).call();
        const ethall_market = this.tokenFromWei(all_market, rewardPool.tokenDecimals)
        rewardPool.all_market = ethall_market;
        
        await this.timeout(150);

        const all_borrowed = await factoryContract.methods.totalBorrowed(rewardPool.TRC20).call();
        const ethall_borrowed = this.tokenFromWei(all_borrowed, rewardPool.tokenDecimals);
        rewardPool.all_borrowed = ethall_borrowed;
        
        await this.timeout(150);

        const depositAPY = await factoryContract.methods.depositAPY(rewardPool.TRC20).call();
        rewardPool.deposit_apy = depositAPY;
        
        await this.timeout(150);

        const borrowAPR = await factoryContract.methods.borrowAPR(rewardPool.TRC20).call();
        rewardPool.borrow_apy = borrowAPR;
        
        await this.timeout(150);
      });
      store.setStore({
        rewardPools: rewardPools,
      });
      this.checktotals();
      }, 100);
  };
  
  tokenToWei = (amount, decimals) => {
    var weiAmout = BigInteger(Number(amount) * Math.pow(10, decimals)).toString();
    return weiAmout;
  }

  tokenFromWei = (weiamount, decimals) => {
    var amount = toBN(weiamount) / toBN(Math.pow(10, decimals));
    return amount;
  }
  
  onCheckboxChange = () => {
    const checkboxmenu = this.state['checkboxmenu'];
    this.setState({ checkboxmenu: !checkboxmenu });
  }



  render() {
    var mainpageActive = "";
    if (window.location.pathname == '/') {
      mainpageActive = "active";
    }
    var apppageActive = "";
    if (window.location.pathname == '/app') {
      apppageActive = "active";
    }
    
    var marketpageActive = "";
    if (window.location.pathname == '/markets') {
      marketpageActive = "active";
    }
    
    var walletBtn = "Connect Wallet";
    const wallet_address = store.getStore("address");
    const tronWeb = store.getStore("tronWeb");
    if (!tronWeb.installed) {
      walletBtn = "Please install TronLink";
    } else if (tronWeb.installed && !tronWeb.loggedIn) {
      walletBtn = "Please unlock TronLink";
    } else if (tronWeb.installed && tronWeb.loggedIn && wallet_address ) {
      walletBtn = wallet_address.substring(0,6)+'...'+wallet_address.substring(wallet_address.length-4,wallet_address.length);
    }
    
    const prices = this.state['prices'];
    if (prices.KODX.price == 0) {
      axios.get('https://cryptocreatures.org/api/v1/prices')
        .then( (response) => {
          this.fillPools(response.data);
          var currentPool = store.getStore("currentPool");
          currentPool.price = response.data.KODX.price;
          currentPool.change = response.data.KODX.change;
          store.setStore({
            currentPool: currentPool,
          });
          this.setState({prices: response.data});
          console.log("response", this.state.prices);
        })
        .catch( (error) => {
          console.log(error);
        });  
    }
    const total_available = store.getStore("total_available");
    const total_locked = store.getStore("total_locked");
    const checkboxmenu = this.state['checkboxmenu'];
    
    return (
      <header>
        <div className="container">
          <div className="header">
            <div className="logo">
              <a href="/">
                <img
                  src={this.props.darkTheme ? "dark-logo.png" : "logo.png"}
                  alt=""
                />
              </a>
            </div>
            <ul className="menu">
              <li className={marketpageActive} onClick={ () => {  this.navigateTo('/markets') } } style={{ cursor: 'pointer' }} >
                <a >Markets</a>
              </li>
              <li className={apppageActive} onClick={ () => {  this.navigateTo('/app') } } style={{ cursor: 'pointer' }} >
                <a >App</a>
              </li>
              <li className={mainpageActive}  onClick={ () => {  this.navigateTo('/') } } style={{ cursor: 'pointer' }} >
                <a >Balances</a>
              </li>
              <li>
                <a href="">Guide</a>
              </li>
            </ul>
            <div className="connect">
              <button className="button" onClick={ () => {  window.location.reload(); } } >{walletBtn}</button>
              <div className="switch">
                <label for="toggle">
                  <input
                    id="toggle"
                    className="toggle-switch"
                    type="checkbox"
                    onChange={() => {
                      this.props.changeTheme();
                    }}
                  />
                  <div className="sun-moon">
                    <div className="dots"></div>
                  </div>
                  <div className="background">
                    <div className="stars1"></div>
                    <div className="stars2"></div>
                  </div>
                </label>
              </div>
            </div>

            <div className="hamburger-menu">
              <input id="menu__toggle" type="checkbox" checked={checkboxmenu} onChange={ this.onCheckboxChange }/>
              <label className="menu__btn" for="menu__toggle">
                <span></span>
              </label>
              <ul className="menu__box">
                <li className={apppageActive}>
                  <span onClick={ () => {  this.navigateTo('/app') } } className="menu__item">
                    App
                  </span>
                </li>
                <li className={marketpageActive}>
                  <span onClick={ () => {  this.navigateTo('/markets') } }  className="menu__item">
                    Markets
                  </span>
                </li>
                <li  className={mainpageActive}>
                  <span onClick={ () => {  this.navigateTo('/') } } className="menu__item">
                    Balances
                  </span>
                </li>
                <li>
                  <a className="menu__item" href="/">
                    Guide
                  </a>
                </li>
                <li>
                  <button className="button" onClick={ () => {  window.location.reload(); } } >
                  {walletBtn}
                  </button>
                </li>
                <li>
                  <div className="switch">
                    <label for="toggle1">
                      <input
                        id="toggle1"
                        className="toggle-switch"
                        type="checkbox"
                        onChange={() => {
                          this.props.changeTheme();
                        }}
                      />
                      <div className="sun-moon">
                        <div className="dots"></div>
                      </div>
                      <div className="background">
                        <div className="stars1"></div>
                        <div className="stars2"></div>
                      </div>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    address: state.address,
    balance: state.balance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
