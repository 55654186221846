import TronWeb from "tronweb";
import { connect } from "react-redux";
import * as actionTypes from "./store/actions";
import React from "react";
import Utils from "./TronUtils";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Apl from "./components/Apl/Apl";
import Markets from "./components/Markets/Markets";
import Header from "./components/Header/Header";
import { BrowserRouter, Route, withRouter } from "react-router-dom";
import "./App.css";
import "./fonts/inter/stylesheet.css";
import Store from "./store/store";
import { fromWei, toWei, toBN, BN, } from 'web3-utils';

const store = Store.store;
const CONTRACT = store.getStore("poolContract");
var BigInteger = require("big-integer");
const FOUNDATION_ADDRESS = store.getStore("poolContract");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      darkTheme: true,
      tronWeb: {
        installed: false,
        loggedIn: false,
      },
    };
  }

  changeTheme = () => {
    this.state.darkTheme
      ? this.setState({ darkTheme: false })
      : this.setState({ darkTheme: true });
  };

  
  checktotals = () => {
    setTimeout( async () => {
      var rewardPools = store.getStore("rewardPools");
      var total_available = 0;
      var total_locked = 0;

      rewardPools.map((rewardPool) => {
        total_locked = total_locked + rewardPool.locked * rewardPool.price;
        total_available = total_available + rewardPool.total * rewardPool.price;
        console.log(rewardPool.total);
        console.log(rewardPool.price);
      });
      store.setStore({
        total_locked: total_locked,
        total_available: total_available,
      });
        console.log("total_available checktotals");
        console.log(total_available);
      }, 1000);
      
  }


  timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  checkbalances = async () => {
    setTimeout( async () => {
      var rewardPools = store.getStore("rewardPools");
      await rewardPools.map( async (rewardPool) => {
        const ACCOUNT = store.getStore("address");
        let factoryContract = await window.tronWeb
          .contract()
          .at(CONTRACT);
        var contractTRC20 = await window.tronWeb
          .contract()
          .at(rewardPool.TRC20);
        const balance = await contractTRC20.methods.balanceOf(ACCOUNT).call();
        const ethbalance = this.tokenFromWei(balance, rewardPool.tokenDecimals)
        rewardPool.total = ethbalance;
        
        await this.timeout(150);
        
        const lockedbalance = await factoryContract.methods.balanceOf(rewardPool.TRC20,ACCOUNT).call();
        const ethlockedbalance = this.tokenFromWei(lockedbalance, rewardPool.tokenDecimals);
        rewardPool.locked = ethlockedbalance;
        
        await this.timeout(150);
        
        const availablebalance = await factoryContract.methods.totalSupply(rewardPool.TRC20).call();
        const ethavailablebalance = this.tokenFromWei(availablebalance, rewardPool.tokenDecimals);
        rewardPool.available = ethavailablebalance;

        await this.timeout(150);
        
        const all_market = await contractTRC20.methods.balanceOf(CONTRACT).call();
        const ethall_market = this.tokenFromWei(all_market, rewardPool.tokenDecimals)
        rewardPool.all_market = ethall_market;

        await this.timeout(150);
        
        const all_borrowed = await factoryContract.methods.totalBorrowed(rewardPool.TRC20).call();
        const ethall_borrowed = this.tokenFromWei(all_borrowed, rewardPool.tokenDecimals);
        rewardPool.all_borrowed = ethall_borrowed;

        await this.timeout(150);
        
        const depositAPY = await factoryContract.methods.depositAPY(rewardPool.TRC20).call();
        rewardPool.deposit_apy = depositAPY;

        await this.timeout(150);
        
        const borrowAPR = await factoryContract.methods.borrowAPR(rewardPool.TRC20).call();
        rewardPool.borrow_apy = borrowAPR;

        await this.timeout(150);
      });
      store.setStore({
        rewardPools: rewardPools,
      });
      this.checktotals();
      }, 100);
  };
  
  tokenToWei = (amount, decimals) => {
    var weiAmout = BigInteger(Number(amount) * Math.pow(10, decimals)).toString();
    return weiAmout;
  }

  tokenFromWei = (weiamount, decimals) => {
    var amount = toBN(weiamount) / toBN(Math.pow(10, decimals));
    return amount;
  }


  async componentDidMount() {
    this.setState({ loading: true });
    await new Promise((resolve) => {
      const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready,
      };

      if (tronWebState.installed) {
        this.setState({
          tronWeb: tronWebState,
        });

        return resolve();
      }

      let tries = 0;

      const timer = setInterval(() => {
        if (tries >= 10) {
          const TRONGRID_API = "https://api.trongrid.io";

          window.tronWeb = new TronWeb({
            fullHost: TRONGRID_API,
            headers: { "TRON-PRO-API-KEY": 'abb09ece-74db-4a15-8297-2e1d1c940bb2' },
          });

          this.setState({
            tronWeb: {
              installed: false,
              loggedIn: false,
            },
          });

          clearInterval(timer);
          return resolve();
        }

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;

        if (!tronWebState.installed) return tries++;

        this.setState({
          tronWeb: tronWebState,
        });
        store.setStore({
          tronWeb: tronWebState,
        });

        resolve();
      }, 100);
    });

    if (!this.state.tronWeb.loggedIn) {
      // Set default address (foundation address) used for contract calls
      // Directly overwrites the address object as TronLink disabled the
      // function call
//       window.tronWeb.defaultAddress = {
//         hex: window.tronWeb.address.toHex(FOUNDATION_ADDRESS),
//         base58: FOUNDATION_ADDRESS,
//       };

      window.tronWeb.on("addressChanged", async () => {
        
        const address = window.tronWeb.defaultAddress.base58;
        const accountInfo = await window.tronWeb.trx.getAccount(address);
        const balance = window.tronWeb.fromSun(accountInfo.balance);
        store.setStore({
          address: address,
          balance: balance,
          accountInfo: accountInfo,
        });
        this.checkbalances();
        this.props.onReceiveEtherAddress(address);
        this.props.onSetBalance(balance);

        if (this.state.tronWeb.loggedIn) {
          return;
        }
        this.setState({
          tronWeb: {
            installed: true,
            loggedIn: true,
          },
        });
        await Utils.setTronWeb(window.tronWeb,CONTRACT);
        store.setStore({
          tronWeb:this.state.tronWeb,
        });

      });

    } else {
        const address = window.tronWeb.defaultAddress.base58;
        const accountInfo = await window.tronWeb.trx.getAccount(address);
        const balance = window.tronWeb.fromSun(accountInfo.balance);
        store.setStore({
          address: address,
          balance: balance,
          accountInfo: accountInfo,
        });
        this.checkbalances();
        this.props.onReceiveEtherAddress(address);
        this.props.onSetBalance(balance);

      
    }
    this.setState({ loading: false });

  }

  render() {
    const { darkTheme } = this.state;
    const total_available = store.getStore("total_available");
    const total_locked = store.getStore("total_locked");
    return (
      <BrowserRouter>
        <div className="app-header">
          <div className={darkTheme ? "darkTheme flexroot" : "flexroot"}>
            <Header
              changeTheme={this.changeTheme}
              darkTheme={this.state.darkTheme}
            />
            <Route darkTheme={this.state.darkTheme} exact path="/" >
              <main>
                <div className="container">
                  <h1>Balances</h1>
                  <div className="balances-block">
                    <div className="total-balance">
                      <h6>Balance</h6>
                      <div className="number">
                        
                        {this.props.balance}
                        <span> TRX</span>
                      </div>
                    </div>
                    <div className="right-balance-block">
                      <div className="avaible">
                        <h6>Total Available</h6>
                        <div className="number">
                          <span>$</span>{total_available.toFixed(2)}
                        </div>
                      </div>
                      <div className="locked">
                        <h6>
                          Total Locked{" "}
                          <svg
                            width="9"
                            height="12"
                            viewBox="0 0 9 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75008 4.33334H7.20841V3.25001C7.20841 1.75501 5.99508 0.541672 4.50008 0.541672C3.00508 0.541672 1.79175 1.75501 1.79175 3.25001V4.33334H1.25008C0.654248 4.33334 0.166748 4.82084 0.166748 5.41667V10.8333C0.166748 11.4292 0.654248 11.9167 1.25008 11.9167H7.75008C8.34591 11.9167 8.83341 11.4292 8.83341 10.8333V5.41667C8.83341 4.82084 8.34591 4.33334 7.75008 4.33334ZM4.50008 9.20834C3.90425 9.20834 3.41675 8.72084 3.41675 8.12501C3.41675 7.52917 3.90425 7.04167 4.50008 7.04167C5.09591 7.04167 5.58341 7.52917 5.58341 8.12501C5.58341 8.72084 5.09591 9.20834 4.50008 9.20834ZM6.17925 4.33334H2.82091V3.25001C2.82091 2.32376 3.57383 1.57084 4.50008 1.57084C5.42633 1.57084 6.17925 2.32376 6.17925 3.25001V4.33334Z"
                              fill={this.props.darkTheme ? "#fff" : "#111"}
                            />
                          </svg>
                        </h6>
                        <div className="number">
                          <span>$</span>{total_locked.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="assets-block">
                    <div class="assets-header">
                      <a href="">Assets</a>
                      <a href="">Total</a>
                      <a href="">Available</a>
                      <a href="">
                        Locked{" "}
                        <svg
                          width="9"
                          height="12"
                          viewBox="0 0 9 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.75008 4.33334H7.20841V3.25001C7.20841 1.75501 5.99508 0.541672 4.50008 0.541672C3.00508 0.541672 1.79175 1.75501 1.79175 3.25001V4.33334H1.25008C0.654248 4.33334 0.166748 4.82084 0.166748 5.41667V10.8333C0.166748 11.4292 0.654248 11.9167 1.25008 11.9167H7.75008C8.34591 11.9167 8.83341 11.4292 8.83341 10.8333V5.41667C8.83341 4.82084 8.34591 4.33334 7.75008 4.33334ZM4.50008 9.20834C3.90425 9.20834 3.41675 8.72084 3.41675 8.12501C3.41675 7.52917 3.90425 7.04167 4.50008 7.04167C5.09591 7.04167 5.58341 7.52917 5.58341 8.12501C5.58341 8.72084 5.09591 9.20834 4.50008 9.20834ZM6.17925 4.33334H2.82091V3.25001C2.82091 2.32376 3.57383 1.57084 4.50008 1.57084C5.42633 1.57084 6.17925 2.32376 6.17925 3.25001V4.33334Z"
                            fill={darkTheme ? "#fff" : "#111"}
                          />
                        </svg>
                      </a>
                    </div>
                    <Route
                      darkTheme={this.state.darkTheme}
                      exact
                      path="/"
                      component={Home}
                    />
                  </div>
                </div>
              </main>
            </Route>
            <Route exact path="/app" component={Apl} />
            <Route exact path="/markets" component={Markets} />
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    address: state.address,
    balance: state.balance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onReceiveEtherAddress: (address) =>
      dispatch({ type: actionTypes.MM_RECEIVE_ACCOUNT, address }),
    onSetBalance: (balance) => dispatch({ type: actionTypes.BALANCE, balance }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
