
const utils = {
  tronWeb: false,
  contract: false,

  async setTronWeb(tronWeb, CONTRACT) {
    let accountInfo = await tronWeb.trx.getAccount();
    let address = accountInfo.address;
    let addressT = tronWeb.address.fromHex(accountInfo.address);
    console.log(addressT);
    const balance = window.tronWeb.fromSun(accountInfo.balance);
    console.log(address);
    console.log(balance);
    console.log(tronWeb.address.fromHex(accountInfo));
    this.tronWeb = tronWeb;
    console.log(CONTRACT);
    console.log("contract start");
    this.contract = await tronWeb.contract().at(CONTRACT);
    console.log("contract end");
    console.log(this.contract);
  },

  formatMoney(n, c, d, t) {
      var c = isNaN((c = Math.abs(c))) ? 2 : c,
      d = d === undefined ? "." : d,
      t = t === undefined ? "," : t,
      s = n < 0 ? "" : "",
      i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  },
  
};

export default utils;
