import React, { Component } from "react";
import "./Apl.css";
import Store from "../../store/store";
import Utils from "../../TronUtils";

import { fromWei, toWei, toBN, BN, } from 'web3-utils';


Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d === undefined ? "." : d,
    t = t === undefined ? "," : t,
    s = n < 0 ? "" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

const store = Store.store;
const CONTRACT = store.getStore("poolContract");
var BigInteger = require("big-integer");

class Apl extends Component {
  constructor(props) {
    super(props);
    const rewardPools = store.getStore("rewardPools");
    const currentPool = store.getStore("currentPool");
    this.state = {
      tab: 0,
      rewardPools: rewardPools,
      currentPool: currentPool,
      depositamount:null,
      withdrawamount:null,
      repayamount:null,
      borrowamount:null,
      balanceamount:0,
      lockedamount:0,
      borrowedamount:0,
      availableamount:0,
      collateralamount:0,
      unclaimedamount:0,
      borrowapy:0,
      borrowfee:0,
      balNotCh: true,
    };
  }

  timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  
  checkbalances = async () => {
    setTimeout( async () => {
      const { currentPool, balanceamount} = this.state;
      let factoryContract = await window.tronWeb
        .contract()
        .at(CONTRACT);
      let contractTRC20 = await window.tronWeb
        .contract()
        .at(currentPool.TRC20);
      const ACCOUNT = store.getStore("address");

      const balance = await contractTRC20.methods.balanceOf(ACCOUNT).call();
      const ethbalance = this.tokenFromWei(balance, currentPool.tokenDecimals)
      console.log(balance);
      this.setState({ balanceamount: ethbalance });
      this.setState({ balNotCh: false });

      await this.timeout(200);
      
      const unclbalance = await factoryContract.methods.balanceOf(currentPool.TRC20,ACCOUNT).call();
      const ethunclbalance = this.tokenFromWei(unclbalance, currentPool.tokenDecimals)
      this.setState({ unclaimedamount: ethunclbalance });
      
      await this.timeout(200);
      
      const lockedbalance = await factoryContract.methods.deposited(currentPool.TRC20,ACCOUNT).call();
      const ethlockedbalance = this.tokenFromWei(lockedbalance, currentPool.tokenDecimals)
      console.log(lockedbalance);
      this.setState({ lockedamount: ethlockedbalance });
      
      await this.timeout(200);

      const borrowedbalance = await factoryContract.methods.borrowed(currentPool.TRC20,ACCOUNT).call();
      const ethborrowedbalance = this.tokenFromWei(borrowedbalance, currentPool.tokenDecimals)
      this.setState({ borrowedamount: ethborrowedbalance });
      
      await this.timeout(200);

      const availbalance = await factoryContract.methods.calculateCollateral(currentPool.TRC20,ACCOUNT).call();
      const ethavailbalance = this.tokenFromWei(availbalance, currentPool.tokenDecimals)
      this.setState({ availableamount: ethavailbalance });

      await this.timeout(200);

      const borrowfee = await factoryContract.methods.getDues(currentPool.TRC20,ACCOUNT).call();
      const ethborrowfee = this.tokenFromWei(borrowfee, currentPool.tokenDecimals)
      this.setState({ borrowfee: ethborrowfee });
      await this.timeout(200);

      const collateralamount = await factoryContract.methods.asCollateral(currentPool.TRC20,ACCOUNT).call();
      const ethcollateralamount = this.tokenFromWei(collateralamount, currentPool.tokenDecimals)
      this.setState({ collateralamount: ethcollateralamount });

      await this.timeout(200);

      const borrowapy = await factoryContract.methods.borrowAPR(currentPool.TRC20).call();
      this.setState({ borrowapy: parseFloat(borrowapy) });
    }, 1);
    

    setTimeout( async () => {
      const { currentPool, balanceamount} = this.state;
      let factoryContract = await window.tronWeb
        .contract()
        .at(CONTRACT);
      let contractTRC20 = await window.tronWeb
        .contract()
        .at(currentPool.TRC20);
      const ACCOUNT = store.getStore("address");

      const balance = await contractTRC20.methods.balanceOf(ACCOUNT).call();
      const ethbalance = this.tokenFromWei(balance, currentPool.tokenDecimals)
      console.log(balance);
      this.setState({ balanceamount: ethbalance });
      this.setState({ balNotCh: false });

      await this.timeout(150);
      
      const unclbalance = await factoryContract.methods.balanceOf(currentPool.TRC20,ACCOUNT).call();
      const ethunclbalance = this.tokenFromWei(unclbalance, currentPool.tokenDecimals)
      this.setState({ unclaimedamount: ethunclbalance });
      
      await this.timeout(150);
      
      const lockedbalance = await factoryContract.methods.deposited(currentPool.TRC20,ACCOUNT).call();
      const ethlockedbalance = this.tokenFromWei(lockedbalance, currentPool.tokenDecimals)
      console.log(lockedbalance);
      this.setState({ lockedamount: ethlockedbalance });
      
      await this.timeout(150);

      const borrowedbalance = await factoryContract.methods.borrowed(currentPool.TRC20,ACCOUNT).call();
      const ethborrowedbalance = this.tokenFromWei(borrowedbalance, currentPool.tokenDecimals)
      this.setState({ borrowedamount: ethborrowedbalance });
      
      await this.timeout(150);

      const availbalance = await factoryContract.methods.calculateCollateral(currentPool.TRC20,ACCOUNT).call();
      const ethavailbalance = this.tokenFromWei(availbalance, currentPool.tokenDecimals)
      this.setState({ availableamount: ethavailbalance });

      await this.timeout(150);

      const borrowapy = await factoryContract.methods.borrowAPR(currentPool.TRC20).call();
      this.setState({ borrowapy: parseFloat(borrowapy) });        

      await this.timeout(200);

      const borrowfee = await factoryContract.methods.getDues(currentPool.TRC20,ACCOUNT).call();
      const ethborrowfee = this.tokenFromWei(borrowfee, currentPool.tokenDecimals)
      this.setState({ borrowfee: ethborrowfee });
      await this.timeout(200);

      const collateralamount = await factoryContract.methods.asCollateral(currentPool.TRC20,ACCOUNT).call();
      const ethcollateralamount = this.tokenFromWei(collateralamount, currentPool.tokenDecimals)
      this.setState({ collateralamount: ethcollateralamount });
    }, 1100);
    
    setTimeout( async () => {
      const { currentPool, balanceamount} = this.state;
      let factoryContract = await window.tronWeb
        .contract()
        .at(CONTRACT);
      let contractTRC20 = await window.tronWeb
        .contract()
        .at(currentPool.TRC20);
      const ACCOUNT = store.getStore("address");

      const balance = await contractTRC20.methods.balanceOf(ACCOUNT).call();
      const ethbalance = this.tokenFromWei(balance, currentPool.tokenDecimals)
      console.log(balance);
      console.log(ethbalance);
      this.setState({ balanceamount: ethbalance });
      this.setState({ balNotCh: false });

      await this.timeout(300);
      
      const unclbalance = await factoryContract.methods.balanceOf(currentPool.TRC20,ACCOUNT).call();
      const ethunclbalance = this.tokenFromWei(unclbalance, currentPool.tokenDecimals)
      this.setState({ unclaimedamount: ethunclbalance });
      
      await this.timeout(300);
      
      const lockedbalance = await factoryContract.methods.deposited(currentPool.TRC20,ACCOUNT).call();
      const ethlockedbalance = this.tokenFromWei(lockedbalance, currentPool.tokenDecimals)
      console.log(lockedbalance);
      this.setState({ lockedamount: ethlockedbalance });
      
      await this.timeout(300);

      const borrowedbalance = await factoryContract.methods.borrowed(currentPool.TRC20,ACCOUNT).call();
      const ethborrowedbalance = this.tokenFromWei(borrowedbalance, currentPool.tokenDecimals)
      this.setState({ borrowedamount: ethborrowedbalance });
      
      await this.timeout(300);

      const availbalance = await factoryContract.methods.calculateCollateral(currentPool.TRC20,ACCOUNT).call();
      const ethavailbalance = this.tokenFromWei(availbalance, currentPool.tokenDecimals)
      this.setState({ availableamount: ethavailbalance });

      await this.timeout(300);

      const borrowapy = await factoryContract.methods.borrowAPR(currentPool.TRC20).call();
      this.setState({ borrowapy: parseFloat(borrowapy) });
      
      await this.timeout(200);

      const borrowfee = await factoryContract.methods.getDues(currentPool.TRC20,ACCOUNT).call();
      const ethborrowfee = this.tokenFromWei(borrowfee, currentPool.tokenDecimals)
      this.setState({ borrowfee: ethborrowfee });
      await this.timeout(200);

      const collateralamount = await factoryContract.methods.asCollateral(currentPool.TRC20,ACCOUNT).call();
      const ethcollateralamount = this.tokenFromWei(collateralamount, currentPool.tokenDecimals)
      this.setState({ collateralamount: ethcollateralamount });
    }, 3000);
    
  };
  

  getPoolValues = async () => {
    const rewardPools = store.getStore("rewardPools");
    const currentPool = store.getStore("currentPool");
    this.setState({
      tab: 1,
      rewardPools: rewardPools,
      currentPool: currentPool,
    });
  };
  
  tokenToWei = (amount, decimals) => {
    var weiAmout = BigInteger(Number(amount) * Math.pow(10, decimals)).toString();
    return weiAmout;
  }

  tokenFromWei = (weiamount, decimals) => {
    var amount = toBN(weiamount) / toBN(Math.pow(10, decimals));
    return amount;
  }


  contractDeposit = async () => {
    const { currentPool, depositamount} = this.state;
    const ACCOUNT = store.getStore("address");
    let contractTRC20 = await window.tronWeb
      .contract()
      .at(currentPool.TRC20);
    let factoryContract = await window.tronWeb
      .contract()
      .at(CONTRACT);
    let val = [];
    contractTRC20.methods.allowance(ACCOUNT,CONTRACT).call({ from: ACCOUNT, })
    .then(allowance => {
        console.log(allowance);
        console.log(typeof allowance);
        if (allowance.remaining) {
          allowance = allowance.remaining;
        }
        console.log(allowance);
        const ethAllowance = this.tokenFromWei(allowance, currentPool.tokenDecimals)
        console.log(parseFloat(ethAllowance));
        console.log(parseFloat(depositamount));
        if(parseFloat(ethAllowance) < parseFloat(depositamount)) {
          console.log(this.tokenToWei("999999999999999", currentPool.tokenDecimals));
          contractTRC20.methods.approve(CONTRACT, this.tokenToWei("999999999999999", currentPool.tokenDecimals)).send({ from: ACCOUNT, })
            .then(result => factoryContract.methods.deposit(currentPool.TRC20, this.tokenToWei(depositamount, currentPool.tokenDecimals)).send({from: ACCOUNT, }))
            .then(result => {
              val['waiting'] = true
              this.setState(val)
              this.checkbalances();
              console.log(JSON.stringify(result));
            })
            .catch(error => { 
              val['waiting'] = true
              this.setState(val)
              this.checkbalances();
              console.log(error);
            });

        } else {
            factoryContract.methods.deposit(currentPool.TRC20, this.tokenToWei(depositamount, currentPool.tokenDecimals)).send({
              from: ACCOUNT,
            }).then(result => {
              val['waiting'] = true
              this.setState(val)
              console.log(JSON.stringify(result));
              this.checkbalances();


            })
            .catch(error => { 
              val['waiting'] = true
              this.setState(val)
              this.checkbalances();
              console.log(error);
            });
        }
     })
  };

  contractRepay = async () => {
    const { currentPool, repayamount} = this.state;
    const ACCOUNT = store.getStore("address");
    let contractTRC20 = await window.tronWeb
      .contract()
      .at(currentPool.TRC20);
    let factoryContract = await window.tronWeb
      .contract()
      .at(CONTRACT);
    let val = [];
    contractTRC20.methods.allowance(ACCOUNT,CONTRACT).call({ from: ACCOUNT, })
    .then(allowance => {
        if (allowance.remaining) {
          allowance = allowance.remaining;
        }
        const ethAllowance = this.tokenFromWei(allowance, currentPool.tokenDecimals)
        if(parseFloat(ethAllowance) < parseFloat(repayamount)) {
          console.log(this.tokenToWei("999999999999999", currentPool.tokenDecimals));
          contractTRC20.methods.approve(CONTRACT, this.tokenToWei("999999999999999", currentPool.tokenDecimals)).send({ from: ACCOUNT, })
            .then(result => factoryContract.methods.repay(currentPool.TRC20, this.tokenToWei(repayamount, currentPool.tokenDecimals)).send({from: ACCOUNT, }))
            .then(result => {
              val['waiting'] = true
              this.setState(val)
              this.checkbalances();
              console.log(JSON.stringify(result));
            })
            .catch(error => { 
              val['waiting'] = true
              this.setState(val)
              this.checkbalances();
              console.log(error);
            });

        } else {
            factoryContract.methods.repay(currentPool.TRC20, this.tokenToWei(repayamount, currentPool.tokenDecimals)).send({
              from: ACCOUNT,
            }).then(result => {
              val['waiting'] = true
              this.setState(val)
              console.log(JSON.stringify(result));
              this.checkbalances();


            })
            .catch(error => { 
              val['waiting'] = true
              this.setState(val)
              this.checkbalances();
              console.log(error);
            });
        }
     })
  };

  contractWithdraw = async () => {
    const ACCOUNT = store.getStore("address");
    const { currentPool, withdrawamount} = this.state;
    let factoryContract = await window.tronWeb
      .contract()
      .at(CONTRACT);

    console.log(withdrawamount);
    console.log(this.tokenToWei(withdrawamount, currentPool.tokenDecimals));
    factoryContract.methods.withdraw(currentPool.TRC20, this.tokenToWei(withdrawamount, currentPool.tokenDecimals)).send()
    .then( () => { this.checkbalances(); })
    
  };
  
  contractBorrow = async () => {
    const ACCOUNT = store.getStore("address");
    const { currentPool, borrowamount} = this.state;
    let factoryContract = await window.tronWeb
      .contract()
      .at(CONTRACT);

    factoryContract.methods.borrow(currentPool.TRC20, this.tokenToWei(borrowamount, currentPool.tokenDecimals)).send()
    .then( () => { this.checkbalances(); })
    
  };
  
  contractWithdrawall = async () => {
    const ACCOUNT = store.getStore("address");
    const { currentPool, lockedamount} = this.state;
    let factoryContract = await window.tronWeb
      .contract()
      .at(CONTRACT);


    console.log(this.tokenToWei(lockedamount, currentPool.tokenDecimals));
    factoryContract.methods.withdraw(currentPool.TRC20, this.tokenToWei(lockedamount, currentPool.tokenDecimals)).send()
    .then( () => { this.checkbalances(); })
    
  };
  
  contractCollateral = async () => {
    const ACCOUNT = store.getStore("address");
    const { currentPool} = this.state;
    let factoryContract = await window.tronWeb
      .contract()
      .at(CONTRACT);


    factoryContract.methods.setUserUseReserveAsCollateral(currentPool.TRC20, true).send()
    .then( () => { this.checkbalances(); })
    
  };
  
  contractRemove = async () => {
    const ACCOUNT = store.getStore("address");
    const { currentPool} = this.state;
    let factoryContract = await window.tronWeb
      .contract()
      .at(CONTRACT);


    factoryContract.methods.setUserUseReserveAsCollateral(currentPool.TRC20, false).send()
    .then( () => { this.checkbalances(); })
    
  };
  
  onChange = (event) => {
    let val = []
    val[event.target.id] = event.target.value
    this.setState(val)
    console.log(val);
  }

  componentDidMount() {}
  //             <div className={`col flex-row ${this.state.tab === 0 ? "blue-card" : ""}`} onClick={ () => this.changeTab(0) }>
  render() {
    const { currentPool, collateralamount, depositamount, borrowamount, repayamount, withdrawamount, balanceamount, balNotCh, lockedamount, borrowedamount, availableamount, borrowapy, unclaimedamount,borrowfee } = this.state;
    
    const wallet_address = store.getStore("address");
    const tronWeb = store.getStore("tronWeb");
    
    if (tronWeb.installed && tronWeb.loggedIn && wallet_address && balNotCh ) {
      this.checkbalances();
    }
    const total_available = store.getStore("total_available");
    const total_locked = store.getStore("total_locked");
    const currentPoolprice = Utils.formatMoney(parseFloat(currentPool.price), 2, ".", ",");
    const currentPoolLprice = Utils.formatMoney(parseFloat(currentPool.price/1.2), 2, ".", ",");
    const unclaimedamountusd = Utils.formatMoney(parseFloat(currentPool.price * unclaimedamount), 2, ".", ",");
    

    return (
      <main className="nb">
        <div className="container">
          <h1>Balances</h1>
          <div className="balances-block">
            <div className="total-balance">
              <h6>Balance</h6>
              <div className="number">
                
                {balanceamount.formatMoney(4, ".", ",")}
                <span> {currentPool.token}</span>
              </div>
            </div>
            <div className="right-balance-block">
              <div className="avaible">
                <h6>Total Available</h6>
                <div className="number">
                  <span>$</span>{total_available.toFixed(2)}
                </div>
              </div>
              <div className="locked">
                <h6>
                  Total Locked{" "}
                  <svg
                    width="9"
                    height="12"
                    viewBox="0 0 9 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.75008 4.33334H7.20841V3.25001C7.20841 1.75501 5.99508 0.541672 4.50008 0.541672C3.00508 0.541672 1.79175 1.75501 1.79175 3.25001V4.33334H1.25008C0.654248 4.33334 0.166748 4.82084 0.166748 5.41667V10.8333C0.166748 11.4292 0.654248 11.9167 1.25008 11.9167H7.75008C8.34591 11.9167 8.83341 11.4292 8.83341 10.8333V5.41667C8.83341 4.82084 8.34591 4.33334 7.75008 4.33334ZM4.50008 9.20834C3.90425 9.20834 3.41675 8.72084 3.41675 8.12501C3.41675 7.52917 3.90425 7.04167 4.50008 7.04167C5.09591 7.04167 5.58341 7.52917 5.58341 8.12501C5.58341 8.72084 5.09591 9.20834 4.50008 9.20834ZM6.17925 4.33334H2.82091V3.25001C2.82091 2.32376 3.57383 1.57084 4.50008 1.57084C5.42633 1.57084 6.17925 2.32376 6.17925 3.25001V4.33334Z"
                      fill={this.props.darkTheme ? "#fff" : "#111"}
                    />
                  </svg>
                </h6>
                <div className="number">
                  <span>$</span>{total_locked.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          
        </div>

        <div class="container">
          <div class="app-header-main">
            <div class="app-locked-balance">
              <h6>LOCKED BALANCE</h6>
              <div class="number">{lockedamount.formatMoney(4, ".", ",")}{currentPool.token}</div>
            </div>
            <div class="app-header-center">
              <div class="liquid-price">
                <h6>{currentPool.token} LIQUIDATION Coef</h6>
                <div class="number">{currentPoolLprice}USD</div>
              </div>
              <div class="current-price">
                <h6>CURRENT {currentPool.token} PRICE</h6>
                <div class="number">{currentPoolprice}USD</div>
              </div>
            </div>
            <div class="app-borrowed-balance">
              <h6>BORROWED BALANCE</h6>
              <div class="number">{borrowedamount.formatMoney(4, ".", ",")}{currentPool.token}</div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="main-app-block">
            <div class="app-locked">
              <div class="liq-ratio">
                <div class="locked-logo">
                  <img src={currentPool.icon} alt="" />
                  <h6>{currentPool.token} Locked</h6>
                </div>
                <div class="locked-info">
                  <span>Liquidation Ratio</span>
                  <div>120.00%</div>
                </div>
              </div>
              <div class="locked-balance">
                <div class="locked-balance-info">
                  <h6>Balance</h6>
                  <div>{balanceamount.formatMoney(4, ".", ",")} {currentPool.token}</div>
                  <input placeholder={ `0.00 ${currentPool.token}` } id={ 'depositamount' } value={ depositamount } onChange={ this.onChange }></input>
                  {/* <span>0.00 USD</span> */}
                </div>
                <div class="locked-balance-button">
                  <button class="button" onClick={() => this.contractDeposit()}>
                    Lock
                  </button>
                </div>
              </div>
              <div class="locked-balance">
                <div class="locked-balance-info">
                  <h6>Available for Collateral</h6>
                  <div>{lockedamount.formatMoney(4, ".", ",")} {currentPool.token}</div>
                  {/* <span>0.00 USD</span> */}
                </div>
                <div class="locked-balance-button">
                  <button class="button" onClick={() => this.contractCollateral()}>
                    Add 
                  </button>
                </div>
              </div>
              <div class="withdraw">
                <div class="withdraw-balance-info">
                  <h6>Available To Withdraw</h6>
                  <div>{lockedamount.formatMoney(4, ".", ",")} {currentPool.token}</div>
                  <input placeholder={ `0.00 ${currentPool.token}` } id={ 'withdrawamount' } value={ withdrawamount } onChange={ this.onChange }></input>
                  {/* <span>0.00 USD</span> */}
                </div>
                <div class="withdraw-balance-button">
                  <button class="button" onClick={() => this.contractWithdraw()}>
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
            <div class="app-borrowed">
              <div class="borrowed-apy">
                <div class="borrowed-logo">
                  <img src={currentPool.icon} alt="" />
                  <h6>{currentPool.token} Borrowed</h6>
                </div>
                <div class="borrowed-info">
                  <span>Borrow APY</span>
                  <div>{borrowapy}%</div>
                </div>
              </div>
              <div class="borrowed-balance">
                <div class="avaible-balance-info">
                  <h6>Available To Borrow</h6>
                  <div>{availableamount.formatMoney(4, ".", ",")} {currentPool.token}</div>
                  <input placeholder={ `0.00 ${currentPool.token}` } id={ 'borrowamount' } value={ borrowamount } onChange={ this.onChange } ></input>
                </div>
                <div class="avaible-balance-button">
                  <button class="button " onClick={() => this.contractBorrow()}>Borrow</button>
                </div>
              </div>
              <div class="borrowed-balance">
                <div class="borrowed-balance-info">
                  <h6>Collateralized</h6>
                  <div>{collateralamount.formatMoney(4, ".", ",")} {currentPool.token}</div>
                </div>
                <div class="borrowed-balance-button">
                  <button class="button " onClick={() => this.contractRemove()}>Remove </button>
                </div>
              </div>
              <div class="borrowed-avaible">
                <div class="borrowed-balance-info">
                  <h6>Borrowed Balance</h6>
                  <div>{borrowedamount.formatMoney(4, ".", ",")} {currentPool.token} <span class="smalltext">(fee: {borrowfee.formatMoney(4, ".", ",")})</span></div>
                  <input placeholder={ `0.00 ${currentPool.token}` } id={ 'repayamount' } value={ repayamount } onChange={ this.onChange } ></input>
                </div>
                <div class="borrowed-balance-button">
                  <button class="button " onClick={() => this.contractRepay()}>Repay</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="app-rewards">
            <div class="app-rewards-tittle">
              <img src="tron.png" alt="" />
              <h5>KODX Rewards</h5>
            </div>
            <div class="unclaimed">
              <div class="left">
                <h6>Unclaimed Balance</h6>
                <div>{unclaimedamount.formatMoney(4, ".", ",")} {currentPool.token}</div>
              </div>
              <div class="right">
                <h6>${unclaimedamountusd}USD</h6>
                <div>
                  <button class="button"  onClick={() => this.contractWithdrawall()}>Claim</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Apl;
