import React, { Component } from "react";
import "./Home.css";
import Store from "../../store/store";

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d === undefined ? "." : d,
    t = t === undefined ? "," : t,
    s = n < 0 ? "" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

const store = Store.store;

class Home extends Component {
  constructor(props) {
    super(props);
    console.log(store.getStore("rewardPools"));
    const rewardPools = store.getStore("rewardPools");
    this.state = {
      tab: 0,
      rewardPools: rewardPools,
      tabIndex: 0,
    };
  }

  toggleButtons(e) {
    if (window.innerWidth < 769) {
      e.target.closest(".asset").classList.toggle("showButtons");
    }
  }

  showModal(e) {
    e.target
      .closest(".asset")
      .querySelector(".modal")
      .classList.toggle("activeModal");
  }

  closeModal(e) {
    e.target.closest(".modal").classList.toggle("activeModal");
  }

  changeTab = (_rewardPool) => {
    store.setStore({
      currentPool: _rewardPool,
    });
    this.props.history.push("/app");
  };

  getPoolValues = async () => {
    const rewardPools = store.getStore("rewardPools");
    this.setState({
      tab: 1,
      rewardPools: rewardPools,
    });
  };

  renderPools = () => {
    const rewardPools = store.getStore("rewardPools");

    return rewardPools
      .filter((rewardPool) => {
        if (rewardPool.active) {
          return true;
        } else {
          return false;
        }
      })
      .map((rewardPool) => {
        return this.renderRewardPool(rewardPool);
      });
  };

  renderRewardPool = (rewardPool) => {
    return (
      <div className="asset" onClick={(e) => this.toggleButtons(e)}>
        <div className="asset-main" onClick={(e) => e.stopPropagation()}>
          <div className="asset-logo">
            <img src={rewardPool.icon} alt="" />
          </div>

          <div className="title">
            <h6>{rewardPool.token}</h6>
            <div className="price">
              $<span>{rewardPool.price.formatMoney(2, ".", ",")}</span>{" "}
              <span className={rewardPool.change > 0 ? "changed" : "changed-minus"}>
                {rewardPool.change > 0 ? "+" : "-"}
                {rewardPool.change.formatMoney(2, ".", ",")}%
              </span>
            </div>
          </div>
        </div>
        <div className="asset-center">
          <div className="asset-total">
            <div className="price">
              {(rewardPool.total + rewardPool.locked).formatMoney(2, ".", ",")}
            </div>
            <div className="number">
              $<span>{((rewardPool.total + rewardPool.locked) * rewardPool.price).formatMoney(2, ".", ",")}</span>
            </div>
          </div>
          <div className="asset-title">
            <h6>{rewardPool.token}</h6>
          </div>
          <div className="asset-avaible">
            <div className="number">
              {rewardPool.total.formatMoney(2, ".", ",")}
            </div>
            <div className="price">
              $<span>{(rewardPool.total * rewardPool.price).formatMoney(2, ".", ",")}</span>
            </div>
          </div>
          <div className="asset-locked">
            <div className="number">
              {rewardPool.locked.formatMoney(2, ".", ",")}
            </div>
            <div className="price">
              $<span>{(rewardPool.locked * rewardPool.price).formatMoney(2, ".", ",")}</span>
            </div>
          </div>
        </div>

        <div className="asset-buttons">
          <div className="assets-load">
            <button
              className="button asset-button"
              onClick={() => this.changeTab(rewardPool)}
            >
              Load / Unload
            </button>
          </div>
          <div className="assets-send">
            <button
              className="button asset-button"
              onClick={() => this.changeTab(rewardPool)}
            >
              Borrow / Repay
            </button>
          </div>
        </div>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="close" onClick={(e) => this.closeModal(e)}>
              &#10006;
            </div>
            <div className="modal-header">
              <div className="modal-logo">
                <img src={rewardPool.icon} alt="" />
              </div>
              <h3>Send {rewardPool.token}</h3>
              <div className="modal-balance">
                <h6>Avaible Balance</h6>
                <div className="modal-price">
                  {rewardPool.total.formatMoney(2, ".", ",")}<br />
                  $<span>{rewardPool.price.formatMoney(2, ".", ",")} </span>
                  {rewardPool.token} <br />
                  <span className="modal-changed">
                    {rewardPool.change > 0 ? "+" : "-"}
                    {rewardPool.change.formatMoney(2, ".", ",")}%
                  </span>
                </div>
              </div>
            </div>
            <div className="modal-menu">
              <a
                className={this.state.tabIndex === 0 ? "active" : null}
                onClick={() => this.setState({ tabIndex: 0 })}
              >
                Send
              </a>
              <a
                className={this.state.tabIndex === 1 ? "active" : null}
                onClick={() => this.setState({ tabIndex: 1 })}
              >
                Receive
              </a>
            </div>
            {this.state.tabIndex === 0 ? (
              <div>
                <div className="modal-form">
                  <input placeholder="PASTE"></input>
                  <input placeholder="MAX"></input>
                  <label>Minimum transfer amount of 0.000001</label>
                  <input></input>
                  <label className="centr">
                    Leave blank memo if not required by recipient
                  </label>
                </div>
                <div className="modal-progress">
                  <span>Network Fee</span>
                  <div className="modal-progress-bar">
                    <div className="modal-main-progress">
                      <div className="step step-1 active"></div>
                      <div className="line line-1 active"></div>
                      <div className="step step-2 active"></div>
                      <div className="line line-2"></div>
                      <div className="step step-3"></div>
                      <div className="line line-3"></div>
                      <div className="step step-4"></div>
                    </div>
                    <div className="modal-progress-info">.001 {rewardPool.token}</div>
                  </div>
                </div>
                <div className="modal-info">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.875 4.375H8.125V5.625H6.875V4.375ZM6.875 6.875H8.125V10.625H6.875V6.875ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5 12.5Z"
                      fill="black"
                    />
                  </svg>

                  <span> &emsp; Insufficient {rewardPool.token}, reduce network fee</span>
                </div>
                <button className="modal-button">Send</button>
                <div className="modal-footer">
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5001 1.91663C6.21008 1.91663 1.91675 6.20996 1.91675 11.5C1.91675 16.79 6.21008 21.0833 11.5001 21.0833C16.7901 21.0833 21.0834 16.79 21.0834 11.5C21.0834 6.20996 16.7901 1.91663 11.5001 1.91663ZM12.4584 16.2916H10.5417V10.5416H12.4584V16.2916ZM12.4584 8.62496H10.5417V6.70829H12.4584V8.62496Z"
                      fill="black"
                    />
                  </svg>
                  <span>
                    &emsp;Ensure Trust Wallet App is open before continuing
                  </span>
                </div>
              </div>
            ) : null}
            {this.state.tabIndex === 1 ? (
              <div>
                <div className="modal-form">
                  <input placeholder="PASTE"></input>
                  <input placeholder="MAX"></input>
                  <label>Minimum transfer amount of 0.000001</label>
                  <input></input>
                  <label className="centr">
                    Leave blank memo if not required by recipient
                  </label>
                </div>
                <div className="modal-progress">
                  <span>Network Fee</span>
                  <div className="modal-progress-bar">
                    <div className="modal-main-progress">
                      <div className="step step-1 active"></div>
                      <div className="line line-1 active"></div>
                      <div className="step step-2 active"></div>
                      <div className="line line-2"></div>
                      <div className="step step-3"></div>
                      <div className="line line-3"></div>
                      <div className="step step-4"></div>
                    </div>
                    <div className="modal-progress-info">.001 {rewardPool.token}</div>
                  </div>
                </div>
                <div className="modal-info">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.875 4.375H8.125V5.625H6.875V4.375ZM6.875 6.875H8.125V10.625H6.875V6.875ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5 12.5Z"
                      fill="black"
                    />
                  </svg>

                  <span> &emsp; Insufficient {rewardPool.token}, reduce network fee</span>
                </div>
                <button className="modal-button">Receive</button>
                <div className="modal-footer">
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5001 1.91663C6.21008 1.91663 1.91675 6.20996 1.91675 11.5C1.91675 16.79 6.21008 21.0833 11.5001 21.0833C16.7901 21.0833 21.0834 16.79 21.0834 11.5C21.0834 6.20996 16.7901 1.91663 11.5001 1.91663ZM12.4584 16.2916H10.5417V10.5416H12.4584V16.2916ZM12.4584 8.62496H10.5417V6.70829H12.4584V8.62496Z"
                      fill="black"
                    />
                  </svg>
                  <span>
                    &emsp;Ensure Trust Wallet App is open before continuing
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {}
  //             <div className={`col flex-row ${this.state.tab === 0 ? "blue-card" : ""}`} onClick={ () => this.changeTab(0) }>
  render() {
    return <main className="nb">{this.renderPools()}</main>;
  }
}

export default Home;
